<template>
  <div>
    <navigation-temp></navigation-temp>

    <div class="jumbotron">
      <h2>Dr. Angela Ombuna</h2>
    </div>
    <section id="profile">
        <div class="row container-fluid">
            <!-- <div class="col-4"> -->
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <img class="gallary" src="../../assets/images/profile.jpg" alt="">
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">

            <!-- <div class="col-8"> -->
                <h6> Dr. Angela: BDS(Bachelor of Dental Surgery), MDS(Master of Dental Surgery) Prosthodontics </h6>
                <br>
                <p class="lead">My goal is to make the dental experience as comfortable as possible. I strive to perform top of the line treatment for patients when they are in our office, and provide all knowledge needed to maintain oral health at home.</p>
                    <p class="lead">I care about my patients, and value the opportunity to make a relationship with each of them. </p>

            </div>

        </div>

    </section>

    <section class="footer">
            <footer>
                <the-footer></the-footer>
            </footer>

        </section>
  </div>
</template>
