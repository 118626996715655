<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>Paediatric dentistry</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <img class="gallary" src="@/assets/images/c35.png" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                    <p class="lead">Our dental team is caring, patient and experienced at treating children of all ages and with a range of special needs.</p>
                    <p class="lead"> <strong>Fissure sealants </strong> are placed to prevent decay before it affects a tooth, they are most commonly used on those teeth most prone to cavities. Often, sealants do not require numbing or drilling and are generally painless.</p>
                    <p class="lead"> <strong>Habit breakers  </strong> are appliances that help patients break or stop a dysfunctional habit, like sucking thumbs or placing the tongue in between the front teeth.</p>
                    <p class="lead"> <strong>Space maintainers   </strong> are devices used to leave enough space open for permanent teeth to grow in after baby teeth are lost prematurely. They are important in preventing space loss and in some cases preventing future orthodontic treatment. </p>
                </div>
                  
               
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  