<template>
    <div class="container-fluid padding">
                    <div class="row text-center">
                        <div class="col-md-4">
                            <hr class="light">
                            <h5>URBAN SMILES</h5>
                            <hr class="light">
                            
                            <p>0796198877/ 0765957795</p>
                            <p>urbansmileske@gmail.com</p>
                            <p>3rd floor, Pacific crest Building, Mombasa road</p>


                        </div>

                        
                        <div class="col-md-4">
                            <hr class="light">
                            <h5>WORKING HOURS</h5>
                            <hr class="light">
                            <p>Monday - Friday: 9am - 5 pm</p>
                            <p>Saturday: 9am - 2pm</p>
                            <p>Sundays and public holidays: closed</p>


                        </div>

                        <div class="col-md-4">
                            <hr>
                            <h5>SOCIAL MEDIA</h5>
                            <hr class="light">
                            <p>Facebook: facebook.com/urbandental</p>
                            <p>email: urbansmileske@gmail.com</p>
                            <p>Instagram/twitter: @urbansmilesdentalke</p>


                        </div>
                        <div class="col-12">
                            <hr class="light">
                            <h5>&copy; www.urbansmiles.co.ke</h5>

                        </div>

                    </div>

                </div>
</template>