<template>
    <div>
        
       <main-navigation></main-navigation>


        <section id="slides" class="carousel slide carousel-fade" data-ride="carousel">
            <ul class="carousel-indicators">
                <li data-target="#slides" data-slide-to="0" class="active"></li>
                <li data-target="#slides" data-slide-to="1"></li>
                <li data-target="#slides" data-slide-to="2" ></li>
                <li data-target="#slides" data-slide-to="3"></li>
                <li data-target="#slides" data-slide-to="4" ></li>

            </ul>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="img-fluid" src="../../assets/images/c10.jpg" alt="">
                    <div class="carousel-caption">
                        <h2 class="display-4">Urban Smiles Dental</h2>
                        <h3>Living with healthy smiles</h3>
                        <a href="#about-section"> <button type="button" class="btn btn-outline-light btn-lg">Our services</button> </a>
                        <a href="/booking">  <button type="button" class="btn btn-primary btn-lg">Book Now</button></a>

                    </div>

                </div>
                <div class="carousel-item">
                    <img class="img-fluid" src="../../assets/images/c11.jpg" alt="">
                    <div class="carousel-caption">
                        <h2 class="display-4">Reliable Dental services</h2>
                        <h3>Dental care you trust</h3>
                        <a href="#about-section"><button type="button" class="btn btn-outline-light btn-lg">Our services</button></a> 
                        <a href="/booking"><button type="button" class="btn btn-primary btn-lg">Book Now</button></a> 

                    </div>

                </div>
                <div class="carousel-item">
                    <img class="img-fluid" src="../../assets/images/c4.jpg" alt="">
                    <div class="carousel-caption">
                        <h2 class="display-4">Best care</h2>
                        <h3>Your smile is important to us</h3>
                        <a href="#about-section"> <button  type="button" class="btn btn-outline-light btn-lg">Our services</button></a> 
                            <a href="/booking"> <button type="button" class="btn btn-primary btn-lg">Book Now</button></a> 

                    </div>

                </div>
                <div class="carousel-item">
                    <img class="img-fluid" src="../../assets/images/c12.jpg" alt="">
                    <div class="carousel-caption">
                        <h2 class="display-4">Schedule appointment</h2>
                        <h3>Schedule an appointment to see a dentist</h3>
                        <a href="#about-section"> <button type="button" class="btn btn-outline-light btn-lg">Our services</button> </a>
                            <a href="/booking"> <button type="button" class="btn btn-primary btn-lg">Book Now</button></a>

                    </div>

                </div>
                <div class="carousel-item">
                    <img class="img-fluid" src="../../assets/images/c13.jpg" alt="">
                    
                    <div class="carousel-caption">
                        <h2 class="display-4">Daily clinics</h2>
                        <h3>We have clinics running from Monday to Saturday</h3>
                        <a href="#about-section">  <button type="button" class="btn btn-outline-light btn-lg">Our services</button></a>
                            <a href="/booking"> <button type="button" class="btn btn-primary btn-lg">Book Now</button></a>

                    </div>

                </div>

            </div>

        </section>

        <section id="about-section">
            <div class="container-fluid">
                <div class="row jumbotron">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-9 col-xs-10">
                        <p class="lead">We are a modern state of the art practice located on Mombasa Road at Pacific Mall, Syokimau. Our goal is to make the dental experience as comfortable as possible. We believe every patient deserves exceptional dental care and address every concern with our full attention and expertise. </p>
                        <!-- <p class="lead"> We strive to perform top of the line treatment for patients when they are in our office, and provide all knowledge needed to maintain oral health at home. We care about our patients, and value the opportunity to make a relationship with each of them.</p> -->
                    </div>
                    <div  class="col-xs-12 col-sm-12 col-md-2 col-xl-2">
                        <a href="/profile"><button type="button" class="btn btn-outline-secondary">Dr. Angela Ombuna profile</button></a>
                    </div>

                </div>

               

            </div>
          
            <div id="services-section" class="container-fluid padding">
                <div class="row welcome">
                    <div class=" col-12">
                        <h1 class="display-5">Our services</h1>

                    </div>
                    <hr>

                    <div class="col-12">
                        <p class="lead"> We offer comprehensive dental solutions, from general dentistry and emergency dental care to braces and cosmetic dentistry.
                        To learn how we can help you maintain healthy teeth and gums and a beautiful smile, please contact urban smiles dental today. </p>
                    </div>


                </div>


            </div>

            <div class="container-fluid padding">
                <div class="row text-center padding">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                        <!-- <i class="fa-solid fa-tooth fa-beat"></i> -->
                        <img class="services" src="../../assets/images/c20.jpg" alt="">
                        <h3> Preventive care</h3>
                        <p class="lead">Regular visits to your dentist ensures your teeth, gums and jaw are in the best condition to provide you with good general health and a confident smile.......</p>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                        <img class="services" src="../../assets/images/c30.jpg" alt="">
                        <!-- <i class="fa-solid fa-face-laugh-wink fa-flip"></i> -->
                        <h3>Periodontal care</h3>
                        <p class="lead">Healthy gums create a seal around the tooth, blocking bacteria from attacking the fibres and bones that hold the tooth in place....</p>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                        <img class="services" src="../../assets/images/c32.jpg" alt="">
                        <!-- <i class="fa-solid fa-face-laugh-wink fa-flip"></i> -->
                        <h3>Restorative dentistry</h3>
                        <p class="lead">This involves Tooth coloured fillings, Endodontics and Dental crowns........ </p>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                        <img class="services" src="../../assets/images/c31.jpg" alt="">
                        <!-- <i class="fa-solid fa-face-laugh-wink fa-flip"></i> -->
                        <h3>Paediatric dentistry</h3>
                        <p class="lead">Our dental team is caring, patient and experienced at treating children of all ages and with a range of special needs......</p>

                    </div>


                </div>
                <hr class="my-4">

            </div>
        </section>

        <section id="services-section">
            <div class="container-fluid padding">
                <div class="row padding">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <h2>Are teeth cleanings really necessary?</h2>
                        <h4>Yes.</h4>
                        <p class="lead">  Even with regular at-home brushing and flossing, there will always be some bacteria-filled plaque and tartar that sticks around. Routine dental cleanings help address these stubborn areas and ensure a healthy smile. Remember, if left too long, plaque and tartar will cause tooth decay, gum disease, and other problems.</p>

                        
                        <br>
                        <!-- <a href="#" class="btn btn-primary">Learn more</a> -->
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <img class="gallary" src="../../assets/images/c6.jpg" alt="">

                    </div>

                </div>

            </div>

        </section>

        <section id="contact-section">
            <div class="row mx-auto text-center padding">
                    <div class="col-12">
                        <h3>Our corporate partners and payment options</h3>


                    </div>
                    </div>

            <partiner-section></partiner-section>
            <!-- <div class="container-fluid padding">
                <div class="row mx-auto text-center padding">
                    <div class="col-12">
                        <h3>Our corporate partners and payment options</h3>


                    </div>

                    <div class="horizontal-scrollable row mx-auto text-center padding scrolling-wrapper-flexbox">
                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2">
                        <img class="services" src="../../assets/images/uapeagle.png" alt="">
                        
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2 mx-auto">
                        <img class="services" src="../../assets/images/britams.jpg" alt="">
                       
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2 mx-auto">
                        <img class="services" src="../../assets/images/mua-heritage.jpg" alt="">
                      
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2 mx-auto">
                        <img class="services" src="../../assets/images/AARGA.png" alt="">
                      
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2 mx-auto">
                        <img class="services" src="../../assets/images/kcbmpesa.png" alt="">
                      
                    </div>
                    

                    <div class="col-xs-12 col-sm-6 col-md-2 col-xl-2 mx-auto">
                        <img class="services" src="../../assets/images/mastercard.png" alt="">
                        
                    </div>
                    


                </div>
                    
                   

                </div>
               
            </div> -->


        </section>
      

        <section class="footer">
            <footer>
                <the-footer></the-footer>
            </footer>

        </section>
     

</div>
    
</template>

<style scoped>


html, body {
  height: 100;
  width: 100;
  font-family: 'poppons',sans-serif;
  color: #222;
}

/* .navbar {
  padding: 8rem;
} */
.navbar-nav li {
  padding-right: 20px;
}
.nav-link {
  font-size: 1.1em !important
}


</style>
