<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>DENTAL IMPLANTS</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <img class="gallary" src="@/assets/images/surgical.png" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                   <h3>Dental implants</h3>
                   <p class="lead">Dental implants are the gold standard for replacing missing or damaged teeth. It comprises of an artificial tooth, also known as a crown, placed on top of a small metal post (typically made of titanium) that is inserted into the jaw. </p>
                   <p class="lead">Dental Implants offer patients the benefit of a permanent artificial tooth that is indistinguishable from natural teeth.</p>
                </div>
                  
                
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  