<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>Routine care</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <img class="gallary" src="@/assets/images/c22.jpg" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                   
                   <p class="lead">Regular visits to your dentist ensures your teeth, gums and jaw are in the best condition to provide you with good general health and a confident smile. </p>
                    <p class="lead">This appointment will include checking for cavities, plaque, and tartar on your teeth. You may also need x-rays to help the dentist assess the health of your teeth. These visits are the best way to lower your risk of gum disease and tooth decay and catch issues early on. </p>
                    <p class="lead">If additional work is required, your dentist will explain their concerns, provide detail on a recommended dental health treatment plan and answer any and all questions you may have.</p>
                </div>
                  
                
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  