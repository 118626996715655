<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-light bg-light sticky-top" >
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img src="../../assets/images/newlogo.png" alt=""></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
                    <span class="navbar-toggler-icon"></span>


                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="#">Home</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">About</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">services</a>

                        </li>

                    </ul>

                </div>

            </div>

        </nav>
    </div>
</template>