// import { Router } from "express";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

// import pages
import LoginUser from "./components/pages/LoginUser.vue";
// import HomePage from "./components/pages/HomePage.vue";
import testPage from "./components/pages/testPage";
import DashBoard from "./components/pages/DashBoard";
import GalleryPage from "./components/pages/GalleryPage";
import Profile from "./components/pages/profile";
import booking from "./components/pages/booking";

//import services pages
import CosmeticDentistry from "./components/pages/services/CosmeticDentistry";
import PaediatricDentistry from "./components/pages/services/PaediatricDentistry";
import PeriodontalCare from "./components/pages/services/PeriodontalCare";
import RestorativeDentistry from "./components/pages/services/RestorativeDentistry";
import surgical from "./components/pages/services/Surgical";
import RoutineCare from "./components/pages/services/RoutineCare";



// import Clients from "./components/pages/clients";
// import Payments from "./components/pages/payments";
// import MpesaPayments from "./components/pages/mpesaPayments";
// import requestParcel from "./components/pages/requestParcel.vue";
// import alerts from "./components/pages/alerts";

// import Reports from "./components/pages/reports";
// // import SendMessages from "./components/pages/sendMessages";
// import simpleSms from "./components/pages/simpleSms";
// import apps from "./components/pages/apps"
// import RegisterUser from "./components/pages/RegisterUser"
// import messages from "./components/pages/messages"
// import parcels from "./components/pages/parcels"
// import smsReport from "./components/pages/smsReport"
// import simpleSms from "./components/pages/simpleSms"
// import LoginUser from "./components/pages/LoginUser"
// import store from './store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: testPage },
    // {path: "/old", component: HomePage},
    // { path: "/request/parcel", component: requestParcel },
    // {path: "/alerts", component: alerts},
    { path: "/login", component: LoginUser, meta: { auth: false } },
    // { path: "/register", component: RegisterUser, meta: { auth: false } },
    // { path: "/register", component: RegisterUser, meta: { auth: false } },
    { path: "/dashboard", component: DashBoard, meta: { auth: true }  },
    {path: "/gallery", component:GalleryPage},
    {path: "/profile", component:Profile},
    {path: "/booking", component:booking},
    // { path: "/apps", component: apps, meta: { auth: true }  },

    // services page
    {path: "/services/cosmetic/dentistry", component:CosmeticDentistry},
    {path: "/services/paediatric/dentistry", component:PaediatricDentistry},
    {path: "/services/periodontal/care", component:PeriodontalCare},
    {path: "/services/restorative/dentistry", component:RestorativeDentistry},
    {path: "/services/surgical", component:surgical},
    {path: "/services/routine/care", component:RoutineCare},


    
    // { path: "/reports", component: Reports, meta: { auth: true }  },
    {
      path: "/register/clients",
     
    },
    // { path: "/clients", component: Clients, meta: { auth: true } },
    // { path: "/messages", component: messages, meta: { auth: true }  },
    // { path: "/send/simple/sms", component: simpleSms, meta: { auth: true }  },
    // { path: "/sms/report", component: smsReport, meta: { auth: true }  },
  
    // { path: "/payments", component: Payments, meta: { auth: true } },
    // {path: "/parcels", component: parcels, meta:{auth:true}},
    // { path: "/mpesa/payments", component: MpesaPayments, meta: { auth: true } },


  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ path: '/Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;
