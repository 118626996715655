<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>Restorative dentistry</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <img class="gallary" src="@/assets/images/c32.jpg" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                   <h3>Tooth coloured fillings.</h3>
                   <p class="lead">We use durable composite material to restore teeth that have cavities. Signs of cavities may include toothaches, hot and cold sensitivity, and pain when chewing. Be aware however, that small cavities often have no symptoms at all.</p>
                </div>
                  
                <div class="container-fluid">
                    <h3>Endodontics</h3>
                    <p class="lead">A root canal treatment is needed when we need to save a tooth damaged by severe decay or trauma. Due to the risk of further infection, it is important to treat these problems as soon as they arise</p>

                </div>
                <div class="container-fluid">
                    <h3>Dental crowns</h3>
                    <p class="lead">Dental crowns are a cosmetic and restorative procedure which can preserve the structure of a natural tooth while also dramatically improving the appearance of your smile. This is done by repairing structural damage and improving the shape, colour and overall appearance of cracked, heavily restored or worn teeth.</p>

                </div>
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  