<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img class="logo" src="../../assets/images/newlogo.png" alt="" />
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
                    <span class="navbar-toggler-icon"></span>


                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
  
           
  
            <li class="dropdown">
              <a class="dropbtn nav-link">Services</a>
              <div class="dropdown-content">
                <a href="/services/routine/care">Routine preventive care</a>
                <a href="/services/periodontal/care">Periodontal care</a>
                <a href="/services/restorative/dentistry">Restorative dentistry</a>
                <a href="/services/paediatric/dentistry/">Paediatric dentistry</a>
                <a href="/services/surgical/">Dental implants </a>
                <a href="/services/cosmetic/dentistry/">Cosmetic dentistry </a>
              </div>
            </li>
  
            <li class="nav-item">
              <a class="nav-link" href="/gallery">Gallery</a>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="/profile">Profile</a>
          </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#contact-section">Contacts</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/booking"> appointment</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="/booking">Appointment</a>
            </li> -->
          </ul>
          <!-- right side nav -->
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    computed: {
      isLoggedIn() {
        return this.$store.getters.isLoggedIn;
      },
    },
    methods: {
      handleLogout() {
        this.$store.dispatch("clearToken");
        localStorage.removeItem("token"); // clear your user's token from localstorage
        localStorage.removeItem("user"); // clear your user from localstorage
  
        this.$router.push("/");
      },
    },
    data() {
      return {
        user: "",
      };
    },
    mounted() {
      let user = localStorage.getItem("user");
      this.user = JSON.parse(user);
  
      setInterval(() => {
        this.handleLogout();
      }, 3600000);
    },
  };
  </script>
  <!-- <style scoped>
  
  /* Add a black background color to the top navigation */
  .topnav {
      background-color: #333;
      overflow: hidden;
  }
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  /* Right-aligned section inside the top navigation */
  .topnav-right {
    float: right;
  }
  </style> -->
  <style scoped>
  /* Dropdown Button */
  .dropbtn {
    /* background-color: #04AA6D;
    color: white;
    padding: 16px;
    font-size: 16px; */
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  /* .dropdown-content a:hover {background-color: #ddd;} */
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {background-color: #3e8e41;} */
  
  /* unvisited link */
  /* a:link {
    color: rgb(147, 165, 247);
  } */
  
  /* visited link */
  /* a:visited {
    color: green;
  } */
  
  /* mouse over link */
  a:hover {
    color: rgb(24, 44, 226);
  }
  
  /* selected link */
  a:active {
    color: blue;
  }
  </style>
  