<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>Periodontal care</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <img class="gallary" src="@/assets/images/c30.jpg" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                    <p class="lead">Healthy gums create a seal around the tooth, blocking bacteria from attacking the fibres and bones that hold the tooth in place. </p>
                    <p class="lead">Signs of unhealthy gums include bleeding gums during brushing or flossing, persistent bad breath, receding gums, one or more teeth becoming loose and inflammation (gingivitis).</p>
                    <p class="lead">The main cause of gum disease is plaque, so it is important to remove plaque build-up from teeth on a daily basis by regularly brushing and flossing all teeth with care.</p>
                </div>
                  
               
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  