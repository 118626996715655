<template>
  <div>
    <navigation-temp></navigation-temp>

    <div class="jumbotron">
      <h4>Fill the form below to book appointment with the doctor</h4>
    </div>
    <section id="profile">
      <div class="row container-fluid">
        <!-- <div class="col-4"> -->
          <div class="col-xs-12 col-sm-12 col-lg-4">

          
          <img class="gallary" src="../../assets/images/c23.jpg" alt="" />
        </div>

        <!-- <div class="col-8"> -->
          <div class="col-xs-12 col-sm-12 col-lg-8">

            <div class="container">

                <form @submit.prevent="createAppointment">
  <div class="row ">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" class="form-control" placeholder="Full names" v-model="formData.names">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <input type="text" class="form-control" placeholder="Phone number" v-model="formData.phone">
    </div>
  </div>
  <br>
  <div class="row">
    <div class="  col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="label">Appointment date</label>

      <input type="date" class="form-control" placeholder="Date" v-model="formData.appointment_date">
    </div>
    <div class=" col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
        <label class="label">Appropriate time</label>
        <div class="form-control">
      <div class="field">
          
          <div class="select " >
            <select v-model="formData.time" >
              <option disabled value="">Select time</option>
                <!-- <select name="vat" class="form-control" v-model="formData.vat"> -->
                <option value="morning">Morning</option>

                <option value="mid_morning">Mid Morning</option>
                <option value="afternoon">Afternoon</option>
            </select>
          </div>
        </div>
        </div>
    </div>
  </div>
<br>

  <button class="btn btn-info">Submit</button>
</form>

            </div>
          
        </div>
      </div>
    </section>

    <section class="footer">
      <footer>
        <the-footer></the-footer>
      </footer>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: "",

      formData: {
        appointment_date: "",
        names: "",
        phone: "",
        time: "",
      },
    };
  },
  
  // },
  methods: {
    async createAppointment() {
      const response = await axios.post(
        "https://api.roberms.com/garbage/create/appointment/",
        this.formData
      );
      console.log(response);

      this.$router.replace("/" );
      console.log(this.formData);
    },
  },
};
</script>
