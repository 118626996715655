<template>
    <div>
        <navigation-temp></navigation-temp>
  
      <div class="jumbotron">
        <h2>Cosmetic Dentistry</h2>
      </div>
      <section id="profile">
          <div class="row container-fluid">
              <!-- <div class="col-5"> -->
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">

                  <img class="gallary" src="@/assets/images/cosmetic.png" alt="">
              </div>
  
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                <div class="container-fluid">
                   <h3>Teeth whitening</h3>
                   <p class="lead">Stained and discoloured teeth are extremely common and can affect the way you look and feel.  At Urban Smiles Dental, we will advise you on the best teeth whitening options suitable for you based on the current colour and condition of your teeth. Causes of tooth discolouration include: -</p>
                    <p class="lead"> Food/drinks, Tobacco use, Poor dental Hygien, Advancing age and genetics</p>
                </div>
                  
                <div class="container-fluid">
                    <h3>Ceramic veneers</h3>
                    <p class="lead">Dental veneers are extremely thin sheaths of custom-coloured material designed to cover the front surface of your teeth. Our veneers will match the colour of your surrounding teeth and help improve the appearance of your smile. They are designed to look and fit naturally over your teeth.</p>

                </div>
                 
              </div>
  
          </div>
  
      </section>
  
      <section class="footer">
              <footer>
                  <the-footer></the-footer>
              </footer>
  
          </section>
    </div>
  </template>
  