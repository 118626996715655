<template>
    <div>
        <navigation-temp></navigation-temp>
        <div class="jumbotron">
 <h2> Gallery section</h2>

        </div>
<section id="gallaryimages">


        <div class="  row container-fluid">
            <!-- <div class="col-4"> -->
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                
                <img class="gallary" src="../../assets/images/c25.jpg" alt="">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c18.jpg" alt="">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c17.jpg" alt="">


            </div>


        </div>
        <div class=" row container-fluid">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c11.jpg" alt="">

               

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c16.jpg" alt="">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c23.jpg" alt="">


            </div>


        </div>
        <div class=" row container-fluid">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c28.jpg" alt="">

               

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c50.jpg" alt="">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c51.jpg" alt="">


            </div>


        </div>

        <div class=" row container-fluid">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c1.jpg" alt="">

               

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/c20.jpg" alt="">

            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <img class="gallary" src="../../assets/images/profile.jpg" alt="">


            </div>


        </div>
        
        
        <br>
        <section class="footer">
            <footer>
                <the-footer></the-footer>
            </footer>

        </section>

    </section>
    </div>
</template>