import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store/index.js'
// import { BAlert } from 'bootstrap-vue'

import '../src/assets/css/style.css'


// createApp(App).mount('#app')
const app = createApp(App)

import BaseCard from './components/layouts/BaseCard.vue';
import BaseButton from './components/layouts/BaseButton.vue';
import BaseBadge from './components/layouts/BaseBadge.vue';
import MainNavigation from './components/layouts/MainNavigation';
import NavigationTemp from './components/layouts/NavigationTemp';
import NewNavigation from './components/layouts/NewNavigation';
import theFooter from './components/layouts/theFooter';
import PartinerSection from './components/layouts/Partiners.vue'
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import moment from 'moment-timezone'

moment.tz.setDefault('Africa/Nairobi')

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'



app.use(store);
app.use(router);
// app.use(BootstrapVue);
// app.use(IconsPlugin);
app.component('base-card',BaseCard);
app.component('base-button', BaseButton);
app.component('vue-sidebar', VueSidebarMenuAkahon)
app.component('base-badge',BaseBadge)
app.component('main-navigation', MainNavigation)
app.component('navigation-temp', NavigationTemp)
app.component('new-navigation', NewNavigation)
app.component('the-footer', theFooter)
app.component('partiner-section',PartinerSection)

// app.component('b-alert', BAlert)
// app.use(VueMaterial)

app.mount('#app');

//new one
